
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useKeywordStatistics } from '@/composables/api';
import {
  DEFAULT_PAGE_SIZE,
  SortOrder,
  KeywordStatisticsSortKey,
  exportKeywordStatisticsExcel
} from '@/services/api';
import { formatLocalTime, formatISOString } from '@/utils/format-time';

import { getThousandSeparator } from '@/utils/render';
import download from 'downloadjs';
import dayjs from 'dayjs';
import { usePage } from '@/composables/page';

export default defineComponent({
  setup() {
    const thirtyDayAgo = dayjs().subtract(30, 'day').toISOString();
    const yesterdayEnd = dayjs(`${dayjs().subtract(1, 'day').format('YYYY-MM-DD')} 23:59:59`).toISOString();

    const startDate = ref();
    const endDate = ref();
    const sortKey = ref<KeywordStatisticsSortKey>(KeywordStatisticsSortKey.DATE);
    const sortOrder = ref<SortOrder>(SortOrder.DESCENDING);
    const dateRange = ref(null);

    const router = useRouter();
    const route = useRoute();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);
    const { data, isFetching, isLoading } = useKeywordStatistics({
      startDate,
      endDate,
      page,
      pageSize,
      sortKey,
      sort: sortOrder
    });

    const sortChange = ({ prop, order }) => {
      sortKey.value = prop;
      order === 'ascending'
        ? sortOrder.value = SortOrder.ASCENDING
        : sortOrder.value = SortOrder.DESCENDING;

      if (!prop) {
        sortOrder.value = null;
      }
    };

    const handleDateRangeChange = () => {
      if (!dateRange.value) {
        startDate.value = '';
        endDate.value = '';
        return;
      }

      startDate.value = formatISOString(dateRange.value[0]);
      endDate.value = formatISOString(dateRange.value[1]);
    };

    const handleExcelExport = async() => {
      const data = await exportKeywordStatisticsExcel({
        query: {
          startDate: startDate.value,
          endDate: endDate.value,
          sortKey: sortKey.value,
          sort: sortOrder.value
        }
      });

      const statisticsStartDate = dayjs(`${startDate.value}`).format('YYYYMMDD');
      const statisticsEndDate = dayjs(`${endDate.value}`).format('YYYYMMDD');

      // 下載匯出檔案
      download(
        data,
       `數據 搜尋數據 ${statisticsStartDate}-${statisticsEndDate}.xlsx`,

       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    };

    // cache搜索條件在querystring
    watch([startDate, endDate], ([startDate, endDate]) => {
      router.push({ query: { ...route.query, startDate, endDate } });
    });
    // 從querystring帶入搜索條件
    watch(
      () => route.query,
      (query) => {
        startDate.value = query.startDate || thirtyDayAgo as string;
        endDate.value = query.endDate || yesterdayEnd as string;
        // 避免[null,null], datePicker會顯示當天日期
        if (startDate.value) {
          dateRange.value = [startDate.value, endDate.value];
          return;
        }
        dateRange.value = null;
      },
      { immediate: true }
    );

    onMounted(() => {
      handleDateRangeChange();
    });

    return {
      page,
      data,
      sortKey,
      dateRange,
      sortOrder,
      isLoading,
      isFetching,
      KeywordStatisticsSortKey,
      sortChange,
      setPageQuery,
      formatLocalTime,
      handleExcelExport,
      getThousandSeparator,
      handleDateRangeChange
    };
  }
});
